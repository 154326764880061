// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import CreditdebitCardPayments from "../../blocks/CreditdebitCardPayments/src/CreditdebitCardPayments";
import CfTabletView from "../../blocks/CfTabletView/src/CfTabletView";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import StripeGatewayApiFrontend from "../../blocks/StripeGatewayApiFrontend/src/StripeGatewayApiFrontend";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
CreditdebitCardPayments:{
 component:CreditdebitCardPayments,
path:"/CreditdebitCardPayments"},
CfTabletView:{
 component:CfTabletView,
path:"/CfTabletView"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
AdminConsole2:{
 component:AdminConsole2,
path:"/AdminConsole2"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
StripeGatewayApiFrontend:{
 component:StripeGatewayApiFrontend,
path:"/StripeGatewayApiFrontend"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;